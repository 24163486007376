export const getTypedUnknownObjectWithKey = <T extends string>(
  error: unknown,
  key: T,
): Record<T, unknown> | null => {
  if (error && typeof error === 'object' && key in error) {
    return error as Record<T, unknown>;
  }
  return null;
};

export const getIfErrorHasStatusValue = (error: unknown, status: number) =>
  Boolean(getTypedUnknownObjectWithKey(error, 'status')?.status === status);
