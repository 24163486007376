/**
 * Attempt to make a request multiple times until we get the response we are expecting
 * @returns an object with the `response` and `error` value. If they are **both** `null`
 * then that means the request timed out without any errors
 */
const pollAsyncFn = async <T>({
  requestFn,
  validatorCallback,
  retryOnError,
  pollingRateInMs = 500,
  intervalTimeoutInMs = 5000,
}: {
  requestFn: () => Promise<T>;
  validatorCallback: (response: T) => boolean;
  retryOnError?: boolean;
  pollingRateInMs?: number;
  intervalTimeoutInMs?: number;
}): Promise<{ response: T | null; error: unknown | null }> => {
  const wait = function () {
    return new Promise((resolve) => {
      setTimeout(resolve, pollingRateInMs);
    });
  };

  const startTime = Date.now();
  let response: null | T = null;
  let error: null | unknown = null;
  while (Date.now() - startTime < intervalTimeoutInMs) {
    try {
      error = null;
      response = await requestFn();
      if (validatorCallback(response)) {
        break;
      }
      response = null;
      await wait();
    } catch (err: unknown) {
      error = err;
      if (retryOnError) {
        await wait();
        continue;
      }
      break;
    }
  }

  return { response, error };
};

export { pollAsyncFn };
