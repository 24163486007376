import * as braze from '@braze/web-sdk';
import type { NavigateFunction } from 'react-router-dom';
import { pollAsyncFn } from '../helpers/pollAsyncFn';
import type {
  AccountInfoResponseType,
  BrazeSubscription,
  GetBrazeSubscriptionsResponse,
} from './Api';
import API from './Api';
import { ErrorLevels, logError } from './Logger';

let brazeKey = '';
let brazeEndpoint = process.env.REACT_APP_BRAZE_ENDPOINT as string;

// The below three variables will adjust based on the environment
if (process.env.REACT_APP_ENV === 'staging') {
  brazeKey = process.env.REACT_APP_STAGING_BRAZE_API_KEY as string;
}
if (process.env.REACT_APP_ENV?.includes('prod')) {
  brazeKey = process.env.REACT_APP_PROD_BRAZE_API_KEY as string;
}
if (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'local') {
  brazeKey = process.env.REACT_APP_DEV_BRAZE_API_KEY as string;
}

braze.initialize(brazeKey, {
  baseUrl: brazeEndpoint,
  enableLogging: false,
});

const loginBrazeUser = (userId?: string, email?: string, phoneNumber?: string): void => {
  // Check if Braze SDK is initialized
  if (!braze) {
    logError('Braze SDK not initialized', 'ALERT');
  } else if (!userId || userId.length === 0) {
    logError('Braze user ID not valid', 'ALERT');
    return;
  } else {
    // Change to the new user
    braze.changeUser(userId);
    braze.openSession();
    if (email && email.length !== 0) {
      braze.getUser()?.setEmail(email);
    }
    if (phoneNumber && phoneNumber.length !== 0) {
      braze.getUser()?.setPhoneNumber(phoneNumber);
    }
  }
};

const BRAZE_ID_ERROR_MESSAGE = 'Failed to process Braze ID';
const BRAZE_UPDATE_ERROR_MESSAGE = 'Failed to update Braze subscriptions';

/**
 * Should be requested after account creation.
 * Fetches the user account info until we have valid a braze ID. Once there we
 * attempt to fetch the braze subscription info. Then we update the `sms` subscription
 * with the correct subscription value based on if the user `checked` the agreement in
 * the form or not.
 */
async function handleBrazeId({
  setLoading,
  navigate,
  checked,
  successPath,
  failurePath,
}: {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: NavigateFunction;
  /** if the subscription agreement is checked */
  checked: boolean;
  successPath: string;
  failurePath?: string;
}) {
  const handleError = (error: unknown) => {
    setLoading(false);
    if (failurePath) {
      navigate(failurePath);
    } else {
      throw error;
    }
  };

  setLoading(true);

  const hasBrazeId = (response?: AccountInfoResponseType) => Boolean(response?.brazeId);

  const { response: accountInfo, error: accountInfoError } = await pollAsyncFn({
    validatorCallback: hasBrazeId,
    requestFn: API.GetAccountInfo,
  });

  // the request returned an error
  if (accountInfoError) {
    logError(BRAZE_ID_ERROR_MESSAGE, ErrorLevels.WARN, accountInfoError);
    handleError(accountInfo);
  }

  // we didn't get the data we wanted -- log and break out early
  if (!accountInfo?.brazeId) {
    const accountInfoTimeoutError = new Error('Timeout: brazeId not returned');
    logError(BRAZE_ID_ERROR_MESSAGE, ErrorLevels.WARN, accountInfoTimeoutError);
    handleError(accountInfoTimeoutError);
    return;
  }

  // get the braze subscription status
  braze.changeUser(accountInfo.brazeId);

  const hasBrazeSubscriptionData = (response?: GetBrazeSubscriptionsResponse) =>
    Boolean(response?.sms);

  const { response: subscriptionData, error: subscriptionError } = await pollAsyncFn({
    validatorCallback: hasBrazeSubscriptionData,
    requestFn: API.getBrazeSubscriptions,
    retryOnError: true, // we might get 500 responses if the updates haven't been processed yet
    intervalTimeoutInMs: 10_000, // give it enough time to process
  });

  // the request returned an error
  if (subscriptionError) {
    logError(BRAZE_UPDATE_ERROR_MESSAGE, ErrorLevels.WARN, subscriptionError);
    handleError(subscriptionError);
  }

  // we didn't get the data we wanted -- log and break out early
  if (!subscriptionData?.sms) {
    const subscriptionUpdateTimeoutError = new Error('Timeout: brazeId not updated');
    logError(BRAZE_UPDATE_ERROR_MESSAGE, ErrorLevels.WARN, subscriptionUpdateTimeoutError);
    handleError(subscriptionUpdateTimeoutError);
    return;
  }

  // update the braze subscriptions
  const updatedSmsSubscriptions: BrazeSubscription[] = subscriptionData.sms.map(
    (smsSubscriptionStatus) => ({
      ...smsSubscriptionStatus,
      name: smsSubscriptionStatus.name || 'SMS Updates',
    }),
  );

  // build a list of promises to be called at the same time -- instead of waiting for each one
  const subscriptionUpdatePromises: Promise<unknown>[] = [];
  for (const subscriptionStatus of updatedSmsSubscriptions) {
    subscriptionUpdatePromises.push(
      API.updateBrazeSubscriptions({
        notificationChannel: 'sms',
        subscriptionGroupId: subscriptionStatus.subscriptionGroupId,
        status: checked ? 'subscribed' : 'unsubscribed',
      }),
    );
  }

  // wait for all of the in-flight requests
  await Promise.allSettled(subscriptionUpdatePromises);

  setLoading(false);
  navigate(successPath);
}

export { handleBrazeId, loginBrazeUser };
